var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.search_status === _vm.STATUS.finished
        ? _c("div", [
            _vm.items.length
              ? _c(
                  "div",
                  _vm._l(_vm.items, function (item) {
                    return _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("gwas-description", {
                            attrs: { study_data: item },
                          }),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _c("div", [
                  _vm._v("\n      No results found. "),
                  _c("a", { attrs: { href: "/gwas/upload/" } }, [
                    _vm._v("Upload your data."),
                  ]),
                ]),
          ])
        : _vm.search_status === _vm.STATUS.pending
        ? _c("div", [_vm._m(0)])
        : _vm.search_status === _vm.STATUS.error
        ? _c("div", [
            _vm._v("\n    An error occurred. Please try again later.\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vue-paginator", {
        attrs: { resource_url: _vm.data_url, options: _vm.page_options },
        on: {
          update: _vm.updateResource,
          request_start: function ($event) {
            _vm.search_status = _vm.STATUS.pending
          },
          request_error: function ($event) {
            _vm.search_status = _vm.STATUS.error
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-secondary",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }