var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", { attrs: { "border-variant": "light" } }, [
    _c(
      "a",
      {
        staticClass: "study-title align-middle text-info",
        attrs: { href: _vm.study_data.url },
      },
      [_vm._v(_vm._s(_vm.study_data.label))]
    ),
    _vm._v(" "),
    _vm.study_data.is_public
      ? _c("span", { staticClass: "badge badge-info align-middle" }, [
          _vm._v("Public"),
        ])
      : _c("span", { staticClass: "badge badge-warning align-middle" }, [
          _vm._v("Private"),
        ]),
    _vm._v(" "),
    _vm.study_data.ingest_status === 0
      ? _c("span", { staticClass: "badge badge-warning align-middle" }, [
          _vm._v("Pending"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.study_data.ingest_status === 1
      ? _c("span", { staticClass: "badge badge-danger align-middle" }, [
          _vm._v("Error"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pmid_link
      ? _c("span", [
          _c(
            "a",
            {
              staticClass: "badge badge-dark align-middle",
              attrs: { href: _vm.pmid_link, target: "_blank" },
            },
            [_vm._v("PubMed")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.study_data.study_name
      ? _c("span", { staticClass: "text-secondary" }, [
          _vm._v("Study: "),
          _c("em", [_vm._v(_vm._s(_vm.study_data.study_name))]),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "text-muted" }, [
      _vm._v("Uploaded: "),
      _c("em", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm._f("date")(_vm.study_data.created))),
      ]),
      _vm._v(" by\n    "),
      _c("em", [_vm._v(_vm._s(_vm.study_data.owner_name))]),
    ]),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }