var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "b-jumbotron",
            {
              staticClass: "search-field",
              attrs: { fluid: true, lead: "Upload. Analyze. Share." },
            },
            [
              _c("p", [
                _vm._v(
                  "Make GWAS region and Manhattan plots. Private, public, or shared only with collaborators."
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.doSearch.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: "Search by label, study name, or PMID",
                        },
                        model: {
                          value: _vm.search_text,
                          callback: function ($$v) {
                            _vm.search_text = $$v
                          },
                          expression: "search_text",
                        },
                      }),
                      _vm._v(" "),
                      _c("b-input-group-append", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            attrs: { type: "submit" },
                          },
                          [_vm._v("Search")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _vm._v("\n            Filter(s):\n            "),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "filter-published", inline: "" },
                          model: {
                            value: _vm.filter_published,
                            callback: function ($$v) {
                              _vm.filter_published = $$v
                            },
                            expression: "filter_published",
                          },
                        },
                        [_vm._v("Published\n            ")]
                      ),
                      _vm._v(" "),
                      _vm.is_authenticated
                        ? _c(
                            "b-form-checkbox",
                            {
                              attrs: { id: "filter-mine", inline: "" },
                              model: {
                                value: _vm.filter_mine,
                                callback: function ($$v) {
                                  _vm.filter_mine = $$v
                                },
                                expression: "filter_mine",
                              },
                            },
                            [_vm._v("Mine\n            ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container" },
      [_c("generic-gwas-list", { attrs: { data_url: _vm.search_url } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }